<template>
  <fm-form-dialog
    form-title="发文申请"
    :open-dialog.sync="modal"
    :form-parms="formParms"
    :old-data="formData"
    :mask-closable="false"
    label-alone
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="modal = false">
  </fm-form-dialog>
</template>

<script>
import {
  officialDocumentSendRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '类型',
          selectDatas: [{key: '院级', label: '院级'}, {key: '支部', label: '支部'}],
          key: 'type'
        },
        {
          type: 'textarea',
          label: '文件名称',
          key: 'title',
          check: {
            required: true
          }
        },
        // {
        //   type: 'input',
        //   label: '发文号',
        //   key: 'sendCode'
        // },
        {
          type: 'input',
          label: '发文份数',
          key: 'num',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'datePicker',
          label: '发文日期',
          key: 'sendTime',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '归入案卷号',
          key: 'getCode'
        },
        {
          type: 'textarea',
          label: '备注',
          key: 'remark'
        }]
        return data
      }
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.id) {
        await officialDocumentSendRequest.update(data.id, data)
      } else {
        await officialDocumentSendRequest.add(data)
      }
      resolve()
      this.$emit('dataUpdate', 'add')
      this.modal = false
    }
  }
}
</script>
