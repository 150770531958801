<template>
  <div class="apply-page">
    <fm-title title-text="发文申请与审批" :titleMenus="titleMenus" @clickTitleMenu="clickTitleMenu">
      <div class="title-sort">
        <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div @click="dealStatus = item.key" class="fm-tabs-nav-item" :class="{'fm-tabs-nav-active': item.key === dealStatus}" v-for="item in dealSwitch" :key="item.key">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </fm-title>
    <deal-block
      class="p-d-s"
      v-if="dealStatus === 'wait_deal' && !batchDeal && waitDealDataList.length > 0"
      :dataList="waitDealDataList"
      :statusMap="statusMap"
      :config="config"
      @dataUpdate="dataUpdate">
    </deal-block>
    <batch-deal-block
      class="p-d-s"
      v-else-if="dealStatus === 'wait_deal' && batchDeal && waitDealDataList.length > 0"
      :config="config"
      :dataList="waitDealDataList"
      @dataUpdate="dataUpdate">
    </batch-deal-block>
    <list-info
      class="p-d-s"
      v-else-if="dealStatus !== 'wait_deal' && (dealStatus === 'dealed' ? dealedDataList : dataList).length > 0"
      :dataList="dealStatus === 'dealed' ? dealedDataList : dataList"
      :config="config"
      :statusMap="statusMap"
      @dataUpdate="dataUpdate">
    </list-info>
    <div class="p-d-s-n" v-else>
      <div>暂无数据</div>
    </div>
    <detail-form @dataUpdate="dataUpdate" ref="dForm" :data="chooseData"></detail-form>
  </div>
</template>

<script>
import detailForm from './apply/form'
import dealBlock from './apply/dealBlock'
import batchDealBlock from './apply/batchDealBlock'
import listInfo from './apply/list'

import {
  officialDocumentSendRequest,
  statusManageRequest
} from '../../api'

export default {
  components: {
    detailForm,
    dealBlock,
    batchDealBlock,
    listInfo
  },
  data () {
    return {
      config: {},
      dealSwitch: [{key: 'wait_deal', label: '待处理'}, {key: 'dealed', label: '已处理'}, {key: 'all', label: '全部'}],
      dealStatus: 'wait_deal',
      batchDeal: false,
      chooseData: null,
      dataList: [],
      statusMap: {}
    }
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
    this.loadConfig()
  },
  activated () {
    this.init()
  },
  computed: {
    waitDealDataList () {
      return this.dataList.filter(v => v.actions && v.actions.filter(v1 => v1.key !== 'back_status_switch').length > 0)
    },
    dealedDataList () {
      return this.dataList.filter(v => !v.actions || v.actions.filter(v1 => v1.key !== 'back_status_switch').length === 0)
    },
    titleMenus () {
      return this.$authFunsProxy.add ? [{key: 'batchDeal', label: '批量操作'}, {key: 'add', label: '添加发文申请'}] : [{key: 'batchDeal', label: '批量操作'}]
    },
    orgId () {
      return this.$store.getters.currentOrgId
    }
  },
  methods: {
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'officialDocumentSend'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.config = {}
      this.config['null'] = c.statusSwitchList.filter(v => v.isHost && !v.tag)
    },
    async dataUpdate () {
      await this.loadData()
    },
    async init () {
      this.dealStatus = 'wait_deal'
      this.batchDeal = false
      this.chooseData = null
      this.dataList = []
      this.loadData()
    },
    async loadData () {
      let datas = await officialDocumentSendRequest.getByAuth({
        statusActionRecord: 1,
        isNotEnd: 1
      })
      datas.forEach(v => {
        v.fileIds = (v.fileIds || '').split(',').filter(v => v).map(v => Number(v))
      })
      this.dataList = datas
    },
    clickTitleMenu (key) {
      if (key === 'add') {
        if (!this.orgId) {
          this.$notify({
            title: '系统提示',
            message: '无当前组织架构，无法添加发文申请。',
            type: 'info'
          })
          return
        }
        this.chooseData = {
          orgId: this.orgId
        }
        this.$refs.dForm.modal = true
      } else if (key === 'batchDeal') {
        if (this.dealStatus !== 'wait_deal') {
          this.dealStatus = 'wait_deal'
          this.batchDeal = true
        } else {
          this.batchDeal = !this.batchDeal
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.apply-page {
  background: #fff;
  border-radius: 0.3rem;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  .title-sort {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .p-d-c {
    width: calc(100% - 40px);
    height: calc(100% - 88px);
  }
  .p-d-s {
    width: 100%;
    height: calc(100% - 48px);
  }
  .p-d-s-n {
    width: 100%;
    height: calc(100% - 48px);
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 20px;
    }
  }
  .left {
    height: calc(100% - 20px);
    overflow-y: auto;
    width: 300px;
    border-right: 1px solid #e8eaec;;
    padding-right: 20px;
  }
  .right {
    height: calc(100% - 20px);
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid #e8eaec;;
    border-right: 1px solid #e8eaec;;
    border-bottom: 1px solid #e8eaec;;
    .right-btns {
      padding:10px 20px;
      display: flex;
      justify-content: space-around;
    }
  }
  .sign-item {
    margin-bottom: 10px;
  }
}
</style>